<template>
  <v-container class="container">
    <v-row>
      <v-col>
        <h1 class="text-h4 py-4">{{ title }}</h1>
      </v-col>
    </v-row>

    <v-img
      :src="require(`@/assets/blogs/${image}`)"
      height="auto"
    ></v-img>
    </br>

    <v-row>
      <v-col>
        <p class="subtitle-1">
          {{ introduction }}
        </p>
      </v-col>
    </v-row>

    <ol>
      <li v-for="(item, index) in items" :key="index">
        <v-card-title>{{ item.title }}</v-card-title>
        <v-card-text class="subtitle-1">{{ item.content }}</v-card-text>
      </li>
    </ol>
    </br>

    <v-row>
      <v-col>
        <p class="subtitle-1">
          {{ conclusion }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BlogTemplate",
  props: {
    title: String,
    introduction: String,
    conclusion: String,
    image: String,
    items: {
      type: Array,
      default: () => [],
      validator(value) {
        // Check if every item in the array is an object with `title` and `content` strings
        return value.every(item =>
          item.hasOwnProperty('title') &&
          typeof item.title === 'string' &&
          item.hasOwnProperty('content') &&
          typeof item.content === 'string'
        );
      }
    }
  },
};
</script>

<style scoped>
.container {
  width: 60%;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
}
</style>
